import './App.css';
import Footer from './components/Footer/Footer';
import { Hero } from './components/Hero/Hero';
import Joins from './components/Joins/Joins';
import Plans from './components/Plans/Plans';
import Programs from './components/Programs/Programs';
import Reasons from './components/Reasons/Reasons';
import Testimonial from './components/Testimonial/Testimonial';

function App() {
  return (
    <div className="App">
        <Hero/>
        <Programs/>
        <Reasons/>
        <Plans/>
        <Testimonial/>
        <Joins/>
        <Footer/>
    </div>
  );
}

export default App;
